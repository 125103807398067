<template>
  <div>
    <div class="reply-container" v-if="!isLock">
      <van-button type="info" block @click="showAdd()">预定</van-button>
    </div>

    <div class="reply-container" v-if="isLock">
      <van-button type="info" block @click="showCancle()">取消预定</van-button>
    </div>

    <!-- 预约 -->
    <van-action-sheet
      :closed="true"
      v-model="showOrder"
      :round="false"
      class="reply_dialog"
      title="预定"
    >
      <van-form @submit="onSubmit">
        <div>
          <van-divider>客户信息</van-divider>
          <van-field
            v-model="orderpara.customerCompany"
            label="客户公司"
            placeholder="请输入客户公司"
            required
            :rules="[{ required: true, message: '' }]"
          />
          <van-field
            v-model="orderpara.customerName"
            label="客户姓名"
            placeholder="请输入客户姓名"
            required
            :rules="[{ required: true, message: '' }]"
          />
         
          <van-field
            v-model="orderpara.customerPhone"
            type="tel"
            label="联系电话"
            placeholder="请输入联系电话"
            required
            :rules="[{ required: true, message: '' }]"
          />
<van-divider>员工信息</van-divider>

<van-field
            v-model="orderpara.saleName"
            label="销售姓名"
            placeholder="请输入销售姓名"
            required
            :rules="[{ required: true, message: '' }]"
          />
          <van-field
            v-model="orderpara.saleDepartment"
            label="销售所在部门"
            placeholder="销售所在部门"
            required
            :rules="[{ required: true, message: '' }]"
          />
         
          <van-field
            v-model="orderpara.salePhone"
            type="tel"
            label="销售电话"
            placeholder="请输入销售电话"
            required
            :rules="[{ required: true, message: '' }]"
          />

<van-divider>备注</van-divider>
          <van-field
            v-model="orderpara.remark"
            rows="4"
            autosize
            label="备注"
            placeholder="请输入备注信息"
            type="textarea"
          />
        </div>
        <div>
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit"
              >预定</van-button
            >
          </div>
        </div>
      </van-form>
    </van-action-sheet>

    <!-- 取消预约 -->
    <van-action-sheet
      :closed="true"
      v-model="isShowCancle"
      :round="false"
      class="reply_dialog"
      title="取消预定"
    >
      <van-form @submit="onCancle">
        <div>


          <van-field
            v-model="cancelOrderpara.cancelReason"
            rows="4"
            autosize
            label="取消原因"
            placeholder="请输入取消原因"
            type="textarea"
             required
            :rules="[{ required: true, message: '' }]"
          />
        </div>
        <div>
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit"
              >取消预定</van-button
            >
          </div>
        </div>
      </van-form>
    </van-action-sheet>
  </div>
</template>
<script>
import { AddHDOrder, CancelHDOrder } from "@/api/HDOrder";
import { Toast } from "vant";

export default {
  props: {
    itemId: {
      type: String,
      default: "",
    },
    isLock: {
      type: Boolean,
      default: false,
    },
  },
   watch:{
  
    //  itemId(val, oldVal) {
    // console.log('itemId',val);
    // },
    //  isLock(val, oldVal) {
    // console.log('islock',val);
    // },
    
 },
  data() {
    return {
      showOrder: false,
      isShowCancle:false,
      orderpara: {
        itemId: "",
        customerName: "",
        customerCompany: "",
        customerTitle: "",
        customerPhone: "",
        saleName:"",
        saleDepartment:"",
        salePhone:""
      },
       cancelOrderpara: {
        itemId: "",
        cancelReason: "",
      },
    };
  },
  created() {
    this.orderpara.itemId = this.itemId;
     this.cancelOrderpara.itemId = this.itemId;
  },
  methods: {
    showAdd() {
      this.showOrder = true;
    },
    showCancle() {
      this.isShowCancle = true;
    },
    async onSubmit(){
       const aresult = await AddHDOrder(this.orderpara);
      if (aresult.data.success) {
          Toast("预约成功");
        this.showOrder = false;
        this.isLock=true;
        this.$parent.changeisLock(true)
      }
    },
    async onCancle(){
         const aresult = await CancelHDOrder(this.cancelOrderpara);
      if (aresult.data.success) {
          Toast("取消预约成功");
        this.isShowCancle = false;
        this.isLock=false;
        this.$parent.changeisLock(false)
      }

    }
  },
};
</script>
<style lang="less" scoped>
.reply-container {
  position: fixed;
  left: 0;
  bottom: 48px;
  height: 50px;
  width: 100%;
  overflow: auto;
  background: #f5f5f5;
  z-index: 1500;
  .submit {
    font-size: 12px;
    color: #fff;
  }
}

.reply_dialog {
  height: 100%;
  max-height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .van-action-sheet__header {
    background: #3296fa;
    color: #fff;
    .van-icon-close {
      color: #fff;
    }
  }
  .van-action-sheet__content {
    flex: 1;
    overflow-y: auto;
    padding: 0 10px 44px;
  }
}

</style>