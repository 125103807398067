      <template>
  <div>
    <van-tabs
      v-model="activeName"
      sticky
      v-if="
        playerOptions.sources.length > 0 &&
        courses.videoUrl != undefined &&
        courses.videoUrl.length > 10 &&
        courses.audioUrl != undefined &&
        courses.audioUrl.length > 10 &&
        audioList.length > 0
      "
    >
      <van-tab
        title="视频"
        name="c"
        v-if="
          playerOptions.sources.length > 0 &&
          courses.videoUrl != undefined &&
          courses.videoUrl.length > 10
        "
      >
        <van-row>
          <van-col span="24">
            <div class="znvideo">
              <video-player
                class="video-player-box vjs-big-play-centered"
                ref="videoPlayer"
                :options="playerOptions"
                :playsinline="true"
                customEventName="customstatechangedeventname"
                @play="onPlayerPlay($event)"
                @pause="onPlayerPause($event)"
                @ready="playerReadied"
                @ended="onPlayerEnded($event)"
                @loadeddata="onPlayerLoadeddata($event)"
                @timeupdate="onPlayerTimeupdate($event)"
                style="object-fit: cover"
              >
              </video-player>
            </div>
          </van-col>
        </van-row>
        <van-row style="margin: 5px" v-if="courses.typeId == 106">
          <van-button type="info" size="large" round block @click="downLoadFile"
            >下载原文件</van-button
          >
        </van-row>
      </van-tab>

      <van-tab
        title="音频"
        name="b"
        v-if="
          audioList.length > 0 &&
          courses.audioUrl != undefined &&
          courses.audioUrl.length > 10
        "
      >
        <van-row>
          <van-col span="24" v-for="(item, index) in audioList" :key="index">
            <div class="znaudio">
              <div class="name">
                {{ item.name }}
              </div>
              <audio-player
                ref="audioPlayer"
                :showPrevButton="false"
                :showNextButton="false"
                :isLoop="false"
                :showVolumeButton="false"
                :progress-interval="100"
                :audio-list="[item.url]"
                :before-play="handleBeforePlay"
                theme-color="rgb(157,29,34)"
              />
            </div>
          </van-col>
        </van-row>
      </van-tab>
    </van-tabs>

    <van-row v-else-if="(courses.videoList && courses.videoList.length > 0)
     || (playerOptions.sources.length > 0 && courses.videoUrl != undefined &&
        courses.videoUrl.length > 10)  ">
      <van-col span="24">
        <div class="znvideo">
          <video-player
            class="video-player-box vjs-big-play-centered"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            customEventName="customstatechangedeventname"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @ready="playerReadied"
            @ended="onPlayerEnded($event)"
            @loadeddata="onPlayerLoadeddata($event)"
            @timeupdate="onPlayerTimeupdate($event)"
            style="object-fit: cover"
          >
          </video-player>
        </div>
      </van-col>
    </van-row>
    <van-row
      style="margin: 5px"
      v-if="courses.typeId == 106&&
        playerOptions.sources.length > 0 && courses.videoUrl != undefined &&
        courses.videoUrl.length > 10  
      "
    >
      <van-button type="info" size="large" round block @click="downLoadFile"
        >下载原文件</van-button
      >
    </van-row>
    <div
      class="m-video-part-new"
      v-if="courses.videoList && courses.videoList.length > 0"
    >
      <ul class="list" ref="partList">
        <li
          v-for="(item, index) in courses.videoList"
          :key="item.id.toString()"
          @click="setcurrentIndex(index)"
          :class="index == currentIndex ? 'part-item on' : 'part-item'"
        >
          <van-tag v-if="item.isOpen" color="#1989fa" plain>全员</van-tag>
          <van-tag v-if="!item.canRead" color="#1989fa" plain>暂无权限</van-tag>
          <span class="vname">{{ item.title }}</span>
        </li>
      </ul>
      <div
        class="spread"
        @click="
          () => {
            this.showVideoList = true;
          }
        "
      >
        <van-icon name="arrow-down" style="padding: 11px 0px" />
      </div>
    </div>

    <van-row v-if="playerOptions.sources.length == 0 && audioList.length > 0">
      <van-col span="24" v-for="(item, index) in audioList" :key="index">
        <div class="znaudio">
          <div class="name">
            {{ item.name }}
          </div>
          <audio-player
            ref="audioPlayer"
            :showPrevButton="false"
            :showNextButton="false"
            :isLoop="false"
            :showVolumeButton="false"
            :progress-interval="100"
            :audio-list="[item.url]"
            :before-play="handleBeforePlay"
            theme-color="rgb(157,29,34)"
          />
        </div>
      </van-col>
    </van-row>

    <van-divider
      v-if="playerOptions.sources.length > 0 || audioList.length > 0"
    />

    <van-popup
      v-model="showVideoList"
      closeable
      position="bottom"
      :style="{ height: '60%' }"
    >
      <van-nav-bar title="视频章节列表" />

      <div class="m-video-part-panel">
        <ul class="list">
          <li
            v-for="(item, index) in courses.videoList"
            :key="item.id.toString()"
            @click="setcurrentIndex(index)"
            :class="index == currentIndex ? 'part-item on' : 'part-item'"
          >
            <van-tag v-if="item.isOpen" color="#1989fa" plain>全员</van-tag>
            <span class="vname">{{ item.title }}</span>
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>  
<script>
import { Toast } from "vant";
import { videoPlayer } from "vue-video-player";

import "video.js/dist/video-js.css";

import AudioPlayer from "@liripeng/vue-audio-player";

import "@liripeng/vue-audio-player/lib/vue-audio-player.css";

//引入josn文件
import videoLanguagezh from "@/utils/json/videoLanguagezh-CN.json";

import { AddBrowsingLog, QueryBrowsingLog } from "@/api/browsingLog";

export default {
  name: "znplayer",
  props: {
    courses: Object,

    browsingLogType: Number,
  },
  data() {
    return {
      showVideoList: false,
      loading: true,
      videoPlayCount: 0, //记录当前页是不是第一次播放
      videoplayTime: 0,
      videopaused: true,

      /**
       * 章节视频列表
       */
      videoSourcesList: [],
      currentIndex: 0,
      currentVideoId: "5f541fd3-7c0c-4278-8e87-bf7709ccc03a",
      playerOptions: {
        // videojs options

        preload: "auto",
        autoplay: false,
        muted: false,
        fluid: true,
        width: document.documentElement.clientWidth,
        language: "zh-CN",
        languages: {
          "zh-CN": videoLanguagezh,
        },
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],

        controlBar: {
          controlslist: "nodownload", //// 添加隐藏下载按钮

          // timeDivider: true, //当前时间和持续时间的分隔符
          // durationDisplay: true, //显示持续时间
          // remainingTimeDisplay: false, //是否显示剩余时间功能
          // fullscreenToggle: true, //全屏按钮

          // 设置控制条组件
          /* 设置控制条里面组件的相关属性及显示与否
    'currentTimeDisplay':true,
    'timeDivider':true,
    'durationDisplay':true,
    'remainingTimeDisplay':false,
    volumePanel: {
      inline: false,
    }
    */
          /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
          children: [
            { name: "playToggle" }, // 播放按钮
            { name: "currentTimeDisplay" }, // 当前已播放时间
            { name: "timeDivider" },
            { name: "progressControl" }, // 播放进度条
            { name: "durationDisplay" }, // 总时间
            {
              // 倍数播放
              name: "playbackRateMenuButton",
              playbackRates: [0.5, 1, 1.25, 1.5, 1.75],
            },
            {
              name: "volumePanel", // 音量控制
              inline: false, // 不使用水平方式
            },
            { name: "FullscreenToggle" }, // 全屏
          ],
        },

        //poster: "/image/shipin2.jpg",
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
      },

      //音频
      currentAudioName: "",
      audioList: [],
      activeName: "c",
    };
  },
  components: {
    AudioPlayer,
    videoPlayer,
  },
  created() {
    this.initData();
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.loading = true;
        this.initData();
        this.onLoad();
      }
    },
  },
  mounted() {},
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  beforeDestroy() {
    this.dobeforeDestroy();
  },
  deactivated() {
    this.dobeforeDestroy();
  },
  methods: {
    // listen event
    onPlayerPlay(player) {
      console.log("player play!", player);
      //播放时更新进度
      // alert(player.cache_.currentTime);

      //
      //alert(player.cache_.currentTime);

      var that = this;
      if (this.videoPlayCount < 2 && this.videoplayTime > 0) {
        //播放时更新进度 兼容手机播放
        player.currentTime(this.videoplayTime);

        //alert(player.cache_.currentTime);
        setTimeout(function () {
          if (that.videoPlayCount < 1) {
            player.pause();
            player.play();
            that.videoPlayCount++;
            //alert("setTimeout");
          }
        }, 900);
        //fix 视频播放进度不可调整
        this.videoPlayCount = 3;
      }
    },
    onPlayerPause(player) {
      console.log("player pause!", player);
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      console.log("player current update state", playerCurrentState);
    },

    // player is ready
    playerReadied(player) {
      player;
      console.log("the player is readied", player);

      player.currentTime(this.videoplayTime);
    },

    //当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata(player) {
      console.log(player);
    },
    onPlayerEnded(player) {
      console.log(player);
      //TOTO:记录当前播放进度
      Toast("视频播放完毕");
    },
    initData() {
      this.activeName = "a";
    },

    dobeforeDestroy() {
      //TOTO:记录当前播放进度
      //alert(this.videoplayTime);
    },
    //当前播放位置发生变化时触发。
   async onPlayerTimeupdate(player) {
      if (this.browsingLogType != undefined && this.browsingLogType > 0) {
        if (this.videoplayTime > player.cache_.currentTime) {
          this.videoplayTime = player.cache_.currentTime;
        }
        //30秒 记录一次视频进度
        if (this.videoplayTime + 30 < player.cache_.currentTime) {
          this.videoplayTime = player.cache_.currentTime;
          try {
            const aresult = await AddBrowsingLog({
              id: 0,
              itemId: this.courses.id,
              itemType: this.browsingLogType,
              partId:
                this.currentVideoId != "" && this.currentVideoId.length == 36
                  ? this.currentVideoId
                  : null,
              currentTime: parseInt(this.videoplayTime),
              totalTime: parseInt(player.cache_.duration),
            });
            if (aresult.data.success) {
              if (
                aresult.data.scoreMessage !== undefined &&
                aresult.data.scoreMessage != ""
              ) {
                Toast({
                  message: aresult.data.scoreMessage,
                  icon: "/image/signin_point_add.png",
                });
              } 
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    },

    // 播放前做的事
    handleBeforePlay(next) {
      this.$refs.audioPlayer.forEach((element) => {
        element.pause();
      });

      console.log(this.$refs.audioPlayer);

      //   // 这里可以做一些事情...
      //   this.currentAudioName = this.audioList[
      //     this.$refs.audioPlayer.currentPlayIndex
      //   ].name;

      next(); // 开始播放
    },

    async onLoad() {
      //是有章节视频
      if (
        this.courses.videoList != undefined &&
        this.courses.videoList.length > 0
      ) {
        this.currentVideoId = this.courses.currentVideoId;

        this.playerOptions.sources = [];
        this.videoSourcesList = [];
        this.courses.videoList.forEach((element, index) => {
          if (element.id == this.currentVideoId) {
            this.currentIndex = index;
          }
          let videoObj = JSON.parse(element.videoUrlJson);
          videoObj.forEach((item) => {
            this.videoSourcesList.push({
              type: "video/mp4",
              src: item.response,
            });
          });
        });
        this.playerOptions.sources = [this.videoSourcesList[this.currentIndex]];
        this.currentVideoId = this.courses.videoList[this.currentIndex].id;
        this.scrollToCurrent();
      } else if (this.courses.videoUrl != undefined) {
        let videoObj = JSON.parse(this.courses.videoUrl);
        this.playerOptions.sources = [];
        videoObj.forEach((item) => {
          this.playerOptions.sources.push({
            type: "video/mp4",
            src: item.response,
          });
        });
      }

      if (this.browsingLogType != undefined && this.browsingLogType > 0) {
        //获取当前播放时间
        try {
          let bresult = await QueryBrowsingLog({
            id: 0,
            itemId: this.courses.id,
            itemType: this.browsingLogType,
            partId:
              this.currentVideoId != "" && this.currentVideoId.length == 36
                ? this.currentVideoId
                : null,
            currentTime: 0,
            totalTime: 0,
          });
          if (bresult.data.success) {
            this.videoplayTime = bresult.data.data.currentTime;

            Toast(
              "根据视频播放历史从" +
                this.s_to_hs(bresult.data.data.currentTime) +
                "秒开始播放"
            );
          }
          //播放

          //暂停
        } catch (error) {
          console.log(error);
        }
      }

      if (this.courses.audioUrl != undefined) {
        this.audioList = [];
        let audioObj = JSON.parse(this.courses.audioUrl);
        audioObj.forEach((item) => {
          this.audioList.push({
            name: item.name,
            url: item.response,
          });
        });
      }

      this.loading = false;
    },

    s_to_hs(s) {
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      var h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? "0" + h : h;
      s = s.length == 1 ? "0" + s : s;
      return h + ":" + s;
    },

    async downLoadFile() {
      let that = this;
      if (
        this.courses.videoUrl != undefined &&
        this.courses.videoUrl.length > 10 &&
        this.playerOptions.sources.length > 0
      ) {
        var vurl = this.playerOptions.sources[0].src; //"http://192.168.10.231:8080/image/test.mp4";
        var fileExtension = vurl.substring(vurl.lastIndexOf(".")).toLowerCase();
        var filename = that.courses.title + fileExtension;

        const res = await fetch(vurl);
        if (res.ok) {
          const blob = await res.blob();
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = filename; //+"."+fileExtension;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      } else {
        Toast("没有要下载的文件");
      }
    },

    downloadFile(url, filename) {
      const aLink = document.createElement("a");
      document.body.appendChild(aLink);
      aLink.style.display = "none";
      aLink.href = url;
      aLink.download = filename;
      aLink.click();
      document.body.removeChild(aLink);
    },

    setcurrentIndex(index) {
      if (
        this.courses.videoList[index].canRead == false
      ) {
        Toast("您的暂无权限回看，继续加油！");
        return;
      }
      this.videoplayTime=0;
      this.currentIndex = index;
      this.currentVideoId = this.courses.videoList[index].id;
      this.playerOptions.sources = [this.videoSourcesList[index]];
    },
    scrollToCurrent: function () {
      var t = this.$refs.partList?.querySelector(".on");
      if (t) {
        var e = t.offsetLeft;
        this.$refs.partList?.scrollTo(e - 50, 0);
      }
    },

    scrollToCurrent2: function () {
      var t = this.$refs.partList2?.querySelector(".on");
      if (t) {
        var e = t.offsetTop;
        this.$refs.partList2?.scrollTo(0, e - 50);
      }
    },
  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 10px 10px 20px;
}
.znaudio {
  margin-top: 40px;
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 25px;
  background-color: whitesmoke;
  /deep/ .name {
    text-align: center;
    line-height: 25px;
  }
}
.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
.znvideo {
  /deep/ .vjs-poster {
    background-color: rgba(157, 29, 34, 0.9);
  }

  video::-internal-media-controls-download-button {
    display: none;
  }

  video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }

  video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }
}

.product-proptext {
  margin-left: 20px;
}

//手机等小屏
@media (min-width: 200px) {
  .m-video-part-new {
    padding: 3.2vmin;
    border-top: 1px solid #e7e7e7;
    font-size: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .m-video-part-new,
  .m-video-part-new .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .m-video-part-new .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .m-video-part-new .list {
    white-space: nowrap;
    list-style-type: none;
    width: 84vmin;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .m-video-part-new .list .part-item {
    padding: 1.33333vmin 2.4vmin;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    font-size: 3.2vmin;
    color: #212121;
    border: 1px solid #ccc;
    border-radius: 1.06667vmin;
    margin-right: 2.13333vmin;
  }

  .m-video-part-new .list .part-item .vname {
    width: 29.86667vmin;
    height: 9.06667vmin;
    line-height: 4.53333vmin;
    display: block;
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
  }

  .m-video-part-new .list .part-item.on {
    color: #fb7299;
    border: 1px solid #fb7299;
  }

  .m-video-part-new .spread {
    height: 11.73333vmin;
    line-height: 11.73333vmin;
  }

  .m-video-part-new .spread i {
    color: #757575;
    font-size: 5.33333vmin;
  }

  .m-video-part-panel .title {
    font-size: 4vmin;
    height: 11.73333vmin;
    line-height: 11.73333vmin;
    padding: 0 4.26667vmin;
  }

  .m-video-part-panel .title i {
    float: right;
    color: #bcbcbc;
  }

  .m-video-part-panel .list {
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: baseline;
    align-content: baseline;
    overflow: auto;
    height: 84.26667vmin;
    padding: 0 4.26667vmin;
  }

  .m-video-part-panel .list .part-item {
    padding: 4.26667vmin 2.93333vmin;
    height: 9.06667vmin;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    font-size: 3.2vmin;
    color: #212121;
    border: 1px solid #ccc;
    border-radius: 1.06667vmin;
    background: #fff;
    margin-bottom: 3.2vmin;
  }

  .m-video-part-panel .list .part-item .vname {
    width: 37.33333vmin;
    height: 9.06667vmin;
    line-height: 4.53333vmin;
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .m-video-part-panel .list .part-item.on {
    color: #fb7299;
    border: 1px solid #fb7299;
  }

  .m-video-part-panel .part-slide-enter-active,
  .m-video-part-panel .part-slide-leave-active {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: -webkit-transform 0.3s;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }

  .m-video-part-panel .part-slide-enter,
  .m-video-part-panel .part-slide-leave-to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

//手机等小屏
@media (min-width: 200px) {
  .m-video-part-new {
    padding: 3.2vmin;
    border-top: 1px solid #e7e7e7;
    font-size: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .m-video-part-new,
  .m-video-part-new .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .m-video-part-new .list {
    white-space: nowrap;
    list-style-type: none;
    width: 84vmin;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .m-video-part-new .list .part-item {
    padding: 1.33333vmin 2.4vmin;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    font-size: 3.2vmin;
    color: #212121;
    border: 1px solid #ccc;
    border-radius: 1.06667vmin;
    margin-right: 2.13333vmin;
  }

  .m-video-part-new .list .part-item .vname {
    width: 29.86667vmin;
    height: 9.06667vmin;
    line-height: 4.53333vmin;
    display: block;
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
  }

  .m-video-part-new .list .part-item.on {
    color: #fb7299;
    border: 1px solid #fb7299;
  }

  .m-video-part-new .spread {
    height: 11.73333vmin;
    line-height: 11.73333vmin;
  }

  .m-video-part-new .spread i {
    color: #757575;
    font-size: 5.33333vmin;
  }

  .m-video-part-panel .title {
    font-size: 4vmin;
    height: 11.73333vmin;
    line-height: 11.73333vmin;
    padding: 0 4.26667vmin;
  }

  .m-video-part-panel .title i {
    float: right;
    color: #bcbcbc;
  }

  .m-video-part-panel .list {
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: baseline;
    align-content: baseline;
    overflow: auto;
    height: 84.26667vmin;
    padding: 0 4.26667vmin;
  }

  .m-video-part-panel .list .part-item {
    padding: 4.26667vmin 2.93333vmin;
    height: 9.06667vmin;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    font-size: 3.2vmin;
    color: #212121;
    border: 1px solid #ccc;
    border-radius: 1.06667vmin;
    background: #fff;
    margin-bottom: 3.2vmin;
  }

  .m-video-part-panel .list .part-item .vname {
    width: 37.33333vmin;
    height: 9.06667vmin;
    line-height: 4.53333vmin;
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .m-video-part-panel .list .part-item.on {
    color: #fb7299;
    border: 1px solid #fb7299;
  }

  .m-video-part-panel .part-slide-enter-active,
  .m-video-part-panel .part-slide-leave-active {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: -webkit-transform 0.3s;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }

  .m-video-part-panel .part-slide-enter,
  .m-video-part-panel .part-slide-leave-to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
//大于700的PC等大屏

@media (min-width: 700px) {
  .m-video-part-new {
    padding: 24px;
    border-top: 1px solid #e7e7e7;
    font-size: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .m-video-part-new,
  .m-video-part-new .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .m-video-part-new .list {
    white-space: nowrap;
    list-style-type: none;
    width: 630px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .m-video-part-new .list .part-item {
    padding: 10px 2.18px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    font-size: 18px;
    color: #212121;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-right: 16px;
  }
  .m-video-part-new .list .part-item .vname {
    width: 224px;
    height: 68px;
    line-height: 24px;
    display: block;
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
  }
  .m-video-part-new .list .part-item.on {
    color: #fb7299;
    border: 1px solid #fb7299;
  }
  .m-video-part-new .spread {
    height: 88px;
    line-height: 88px;
  }
  .m-video-part-new .spread i {
    color: #757575;
    font-size: 40px;
  }
  .m-video-part-panel .title {
    font-size: 18px;
    height: 88px;
    line-height: 88px;
    padding: 0 32px;
  }
  .m-video-part-panel .title i {
    float: right;
    color: #bcbcbc;
  }
  .m-video-part-panel .list {
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: baseline;
    align-content: baseline;
    overflow: auto;
    height: 632px;
    padding: 0 32px;
  }
  .m-video-part-panel .list .part-item {
    padding: 32px 22px;
    height: 68px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    font-size: 18px;
    color: #212121;
    border: 1px solid #ccc;
    border-radius: 1.06667vmin;
    background: #fff;
    margin-bottom: 24px;
  }
  .m-video-part-panel .list .part-item .vname {
    width: 280px;
    height: 68px;
    line-height: 24px;
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .m-video-part-panel .list .part-item.on {
    color: #fb7299;
    border: 1px solid #fb7299;
  }
  .m-video-part-panel .part-slide-enter-active,
  .m-video-part-panel .part-slide-leave-active {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: -webkit-transform 0.3s;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  .m-video-part-panel .part-slide-enter,
  .m-video-part-panel .part-slide-leave-to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
</style>