<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
      </van-sticky>
      <div class="detail">
        <h2 style="text-align: center" v-if="this.type != 104">
          {{ articles.title }}
        </h2>

        <VideoAudioPlayer :courses="articles"></VideoAudioPlayer>

        <div class="acontent" v-html="articles.detailsHtml"></div>

        <!--市场宣传 下载pdf 或ppt 文件-->
        <van-row
          style="margin: 5px"
          v-if="
            articles.typeId == 106 &&
            articles.fileUrl != undefined &&
            articles.fileUrl.length > 30
          "
        >
          <van-button type="info" size="large" round block @click="downLoadFile"
            >下载原文件</van-button
          >
        </van-row>
      </div>
      <van-cell>
        <!--话题-->
        <van-col span="1"></van-col>
        <van-col span="22">
          <div style="margin-top: 10px">
            <van-tag
              @click="toProduct(articles)"
              v-if="
                articles.productName != undefined &&
                articles.productName.length > 0
              "
              size="large"
              color="rgb(255 242 219)"
              text-color="#9d1d22"
              >{{ articles.productName }}
            </van-tag>
          </div>
        </van-col>
      </van-cell>
      <commentScore v-if="this.type > 2000"></commentScore>

      <comment v-if="this.type != 104" :CommentPage="CommentPage"  style="margin-bottom: 33px;"></comment>
      <orderCancel
        :itemId="articles.id"
        :isLock="articles.isLock ? true : false"
      ></orderCancel>
    </div>
  </div>
</template>

<script>
import { getArticleInfo } from "@/api/article";

import CommentScore from "../components/commentScore";

import Comment from "../components/comment";
import OrderCancel from "./components/OrderCancel";
import { getArticleType } from "@/utils/datetype";
import VideoAudioPlayer from "../components/videoAudioPlayer";
import { getDownLoadFilefromUrl } from "@/api/clouddisk";
import { saveAs } from "file-saver";
import { Toast } from "vant";

export default {
  name: "znhdarticledetail",
  data() {
    return {
      loading: true,
      type: 100,
      title: "",
      articles: {},

      CommentPage: {
        current: 1,
        pageSize: 10,
        filterData: {
          itemId: "",
          itemType: 1,
        },
      },
    };
  },
  components: {

    OrderCancel,
    CommentScore,
    Comment,
    VideoAudioPlayer,
  },
  updated() {
    //this.addpreview();
  },
  created() {
    this.initData();
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/hd/detail") {
        if (to.query != from.query) {
          this.loading = true;
          this.initData();
          this.onLoad();
        }
      }
    },
  },
  mounted() {},

  methods: {
    initData() {
      let type = this.$route.query.type;
      this.type = type;
      this.title = getArticleType(type);
    },
    toProduct(item) {
      this.$router.push({
        path: "/article",
        query: { type: item.typeId, productId: item.productId },
      });
    },
    changeisLock(para) {
      this.articles.isLock = para;
    },
    async onLoad() {
      let para = this.$route.query.para;
      let aresult = await getArticleInfo(para); //({ ...this.page })
      if (aresult.data.success) {
        this.articles = aresult.data.data;
        document.title=this.articles.title;
        this.CommentPage.filterData.itemId = this.articles.id;
        this.loading = false;
      } else {
        Toast(aresult.data.errorMessage);
      }
    },
    //加图片预览
    // addpreview() {
    //   var listdiv = document.getElementsByClassName("acontent");
    //   listdiv.forEach((acontentdiv) => {
    //     var imgs = acontentdiv.getElementsByTagName("img");

    //     imgs.forEach((element) => {
    //       element.setAttribute("preview", this.articles.id);
    //     });
    //   });
    //   this.$previewRefresh();
    // },

    async downLoadFile() {
      if (this.isWenXin() && this.isMobile()) {
        Toast("微信中下载受限，请点击右上角三个点，在浏览器中打开下载源文件");
        return;
      }
      Toast("正在下载...");

      let fileObj = JSON.parse(this.articles.fileUrl);
      fileObj.forEach(async (item) => {
        var data = await getDownLoadFilefromUrl(item.response);

        saveAs(data.data, item.name);
      });
    },
    isWenXin: function () {
      if (navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1) {
        return true; // 是微信端
      } else {
        return false;
      }
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },
  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 10px 10px 20px;
}

.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
</style>