var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.playerOptions.sources.length > 0 &&
      _vm.courses.videoUrl != undefined &&
      _vm.courses.videoUrl.length > 10 &&
      _vm.courses.audioUrl != undefined &&
      _vm.courses.audioUrl.length > 10 &&
      _vm.audioList.length > 0
    )?_c('van-tabs',{attrs:{"sticky":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(
        _vm.playerOptions.sources.length > 0 &&
        _vm.courses.videoUrl != undefined &&
        _vm.courses.videoUrl.length > 10
      )?_c('van-tab',{attrs:{"title":"视频","name":"c"}},[_c('van-row',[_c('van-col',{attrs:{"span":"24"}},[_c('div',{staticClass:"znvideo"},[_c('video-player',{ref:"videoPlayer",staticClass:"video-player-box vjs-big-play-centered",staticStyle:{"object-fit":"cover"},attrs:{"options":_vm.playerOptions,"playsinline":true,"customEventName":"customstatechangedeventname"},on:{"play":function($event){return _vm.onPlayerPlay($event)},"pause":function($event){return _vm.onPlayerPause($event)},"ready":_vm.playerReadied,"ended":function($event){return _vm.onPlayerEnded($event)},"loadeddata":function($event){return _vm.onPlayerLoadeddata($event)},"timeupdate":function($event){return _vm.onPlayerTimeupdate($event)}}})],1)])],1),(_vm.courses.typeId == 106)?_c('van-row',{staticStyle:{"margin":"5px"}},[_c('van-button',{attrs:{"type":"info","size":"large","round":"","block":""},on:{"click":_vm.downLoadFile}},[_vm._v("下载原文件")])],1):_vm._e()],1):_vm._e(),(
        _vm.audioList.length > 0 &&
        _vm.courses.audioUrl != undefined &&
        _vm.courses.audioUrl.length > 10
      )?_c('van-tab',{attrs:{"title":"音频","name":"b"}},[_c('van-row',_vm._l((_vm.audioList),function(item,index){return _c('van-col',{key:index,attrs:{"span":"24"}},[_c('div',{staticClass:"znaudio"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('audio-player',{ref:"audioPlayer",refInFor:true,attrs:{"showPrevButton":false,"showNextButton":false,"isLoop":false,"showVolumeButton":false,"progress-interval":100,"audio-list":[item.url],"before-play":_vm.handleBeforePlay,"theme-color":"rgb(157,29,34)"}})],1)])}),1)],1):_vm._e()],1):((_vm.courses.videoList && _vm.courses.videoList.length > 0)
   || (_vm.playerOptions.sources.length > 0 && _vm.courses.videoUrl != undefined &&
      _vm.courses.videoUrl.length > 10)  )?_c('van-row',[_c('van-col',{attrs:{"span":"24"}},[_c('div',{staticClass:"znvideo"},[_c('video-player',{ref:"videoPlayer",staticClass:"video-player-box vjs-big-play-centered",staticStyle:{"object-fit":"cover"},attrs:{"options":_vm.playerOptions,"playsinline":true,"customEventName":"customstatechangedeventname"},on:{"play":function($event){return _vm.onPlayerPlay($event)},"pause":function($event){return _vm.onPlayerPause($event)},"ready":_vm.playerReadied,"ended":function($event){return _vm.onPlayerEnded($event)},"loadeddata":function($event){return _vm.onPlayerLoadeddata($event)},"timeupdate":function($event){return _vm.onPlayerTimeupdate($event)}}})],1)])],1):_vm._e(),(_vm.courses.typeId == 106&&
      _vm.playerOptions.sources.length > 0 && _vm.courses.videoUrl != undefined &&
      _vm.courses.videoUrl.length > 10  
    )?_c('van-row',{staticStyle:{"margin":"5px"}},[_c('van-button',{attrs:{"type":"info","size":"large","round":"","block":""},on:{"click":_vm.downLoadFile}},[_vm._v("下载原文件")])],1):_vm._e(),(_vm.courses.videoList && _vm.courses.videoList.length > 0)?_c('div',{staticClass:"m-video-part-new"},[_c('ul',{ref:"partList",staticClass:"list"},_vm._l((_vm.courses.videoList),function(item,index){return _c('li',{key:item.id.toString(),class:index == _vm.currentIndex ? 'part-item on' : 'part-item',on:{"click":function($event){return _vm.setcurrentIndex(index)}}},[(item.isOpen)?_c('van-tag',{attrs:{"color":"#1989fa","plain":""}},[_vm._v("全员")]):_vm._e(),(!item.canRead)?_c('van-tag',{attrs:{"color":"#1989fa","plain":""}},[_vm._v("暂无权限")]):_vm._e(),_c('span',{staticClass:"vname"},[_vm._v(_vm._s(item.title))])],1)}),0),_c('div',{staticClass:"spread",on:{"click":function () {
          this$1.showVideoList = true;
        }}},[_c('van-icon',{staticStyle:{"padding":"11px 0px"},attrs:{"name":"arrow-down"}})],1)]):_vm._e(),(_vm.playerOptions.sources.length == 0 && _vm.audioList.length > 0)?_c('van-row',_vm._l((_vm.audioList),function(item,index){return _c('van-col',{key:index,attrs:{"span":"24"}},[_c('div',{staticClass:"znaudio"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('audio-player',{ref:"audioPlayer",refInFor:true,attrs:{"showPrevButton":false,"showNextButton":false,"isLoop":false,"showVolumeButton":false,"progress-interval":100,"audio-list":[item.url],"before-play":_vm.handleBeforePlay,"theme-color":"rgb(157,29,34)"}})],1)])}),1):_vm._e(),(_vm.playerOptions.sources.length > 0 || _vm.audioList.length > 0)?_c('van-divider'):_vm._e(),_c('van-popup',{style:({ height: '60%' }),attrs:{"closeable":"","position":"bottom"},model:{value:(_vm.showVideoList),callback:function ($$v) {_vm.showVideoList=$$v},expression:"showVideoList"}},[_c('van-nav-bar',{attrs:{"title":"视频章节列表"}}),_c('div',{staticClass:"m-video-part-panel"},[_c('ul',{staticClass:"list"},_vm._l((_vm.courses.videoList),function(item,index){return _c('li',{key:item.id.toString(),class:index == _vm.currentIndex ? 'part-item on' : 'part-item',on:{"click":function($event){return _vm.setcurrentIndex(index)}}},[(item.isOpen)?_c('van-tag',{attrs:{"color":"#1989fa","plain":""}},[_vm._v("全员")]):_vm._e(),_c('span',{staticClass:"vname"},[_vm._v(_vm._s(item.title))])],1)}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }