var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.loading)?_c('van-loading',{staticClass:"loading",attrs:{"color":"#9d1d22","text-color":"#9d1d22","size":"24px"}},[_vm._v("加载中...")]):_vm._e(),(_vm.loading == false)?_c('div',[_c('van-sticky',[_c('van-nav-bar',{attrs:{"title":_vm.title,"left-arrow":""},on:{"click-left":function($event){return _vm.$router.back()}}})],1),_c('div',{staticClass:"detail"},[(this.type != 104)?_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.articles.title)+" ")]):_vm._e(),_c('VideoAudioPlayer',{attrs:{"courses":_vm.articles}}),_c('div',{staticClass:"acontent",domProps:{"innerHTML":_vm._s(_vm.articles.detailsHtml)}}),(
          _vm.articles.typeId == 106 &&
          _vm.articles.fileUrl != undefined &&
          _vm.articles.fileUrl.length > 30
        )?_c('van-row',{staticStyle:{"margin":"5px"}},[_c('van-button',{attrs:{"type":"info","size":"large","round":"","block":""},on:{"click":_vm.downLoadFile}},[_vm._v("下载原文件")])],1):_vm._e()],1),_c('van-cell',[_c('van-col',{attrs:{"span":"1"}}),_c('van-col',{attrs:{"span":"22"}},[_c('div',{staticStyle:{"margin-top":"10px"}},[(
              _vm.articles.productName != undefined &&
              _vm.articles.productName.length > 0
            )?_c('van-tag',{attrs:{"size":"large","color":"rgb(255 242 219)","text-color":"#9d1d22"},on:{"click":function($event){return _vm.toProduct(_vm.articles)}}},[_vm._v(_vm._s(_vm.articles.productName)+" ")]):_vm._e()],1)])],1),(this.type > 2000)?_c('commentScore'):_vm._e(),(this.type != 104)?_c('comment',{staticStyle:{"margin-bottom":"33px"},attrs:{"CommentPage":_vm.CommentPage}}):_vm._e(),_c('orderCancel',{attrs:{"itemId":_vm.articles.id,"isLock":_vm.articles.isLock ? true : false}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }