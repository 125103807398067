import request from '@/utils/request'

export function AddHDOrder(params) {
  return request({
    url: '/api/HDOrder/Add',
    data: params,
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function AddHDSign(params) {
  return request({
    url: '/api/HDOrder/AddSign',
    data: params,
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
///api/HDOrder/Cancel
export function CancelHDOrder(params) {
    return request({
      url: '/api/HDOrder/Cancel',
      data: params,
      method: 'post',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
  
    })
  }

export function getMyHDOrderList(params) {
    return request({
      url: '/api/HDOrder/MyList',
      data: { ...params },
      method: 'post',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
  
    })
  }